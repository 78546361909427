@import url('https://fonts.googleapis.com/css?family=Bitter|Montserrat');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono|Rubik');


.nav-links {
    text-decoration: none;
    color: #fff;
    font-family: Montserrat, sans-serif;
    font-weight: 700;
    display: inline-block;
}

.nav-list {
    display: inline-block;
    margin: 0 2em;
    justify-content: center;
}

.nav-container {
    justify-content: center;
    /* align-content: flex-start; */
}

h3 {
    font-family: Roboto Mono;
    color: #55286F;
    margin: 0;
    padding: 0;
}

h4 {
    font-family: Rubik, sans-serif;
    color: #272525;
    margin: 0;
    padding: 0;
}

a {
    font-weight: 600;
    text-decoration: none;
    color: #55286F;
}

.other-links p {
    color: #55286F;
    font-family: Montserrat, sans-serif;
    padding: 0;
    margin: 0;
    list-style-position: inside;
}


.subheader p {
    text-decoration: none;
    padding: 0;
    list-style-position:inside;
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    /* margin-top: 2em; */
}

.content-links {
    margin: 3em 0;
}

.content-links h4 a {
    margin: 0;
    padding: 0;
}

.content-links a:hover {
    border-bottom: 3px solid #55286F;
    margin: 0;
}

figure img {
    width: 4em;
    border-radius: 2em;
    margin: 0;
}

p {
    font-family: Roboto Mono, sans-serif;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    list-style-position: inside;
}

ul, li{
    margin: 0;
    padding: 0;
}

strong {
    font-weight: 600;
    color: #413f3f;
}


.content-container {
    background-color: #d3d3d3;
    height: 100%;
    width: 100%;
    padding: 3em 25em 3em 3em;
}

.content-container p {
    font-family: Rubik;
    color: #fff;
    font-size: 20px;
    text-align: left;
}

.content-container h3 {
    font-family: Roboto Mono;
    font-size: 30px;
    margin-bottom: 2em;
    text-align: left;
}
.content-container h4 {
    font-family: Roboto Mono;
    font-size: 22px;
    color: #696969;
    margin-bottom: 2em;
    display: flex;
    text-align: left;
}

.content-container ul {
    padding: 0 0 1em 0;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
}

.content-container li a {
    font-family: Rubik;
    /* margin: 0 3em 1em 0; */
}

.content-container a:hover {
    border-bottom: 3px solid #55286F;
}

.page-layout {
    display: flex;
    margin-left: 10px;
}

.sidebar-container {
    z-index: 2;
    position: fixed;
    height: 100%;
    width: 10em;
    text-align: left;
    top: 0;
}

.menu {
    background-color: #fff;
    text-align: center;
    padding: 4.2em 1.3em;
    margin: 3em 0;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
}

.menu li {
    margin-top: 2em;
}

.menu img {
    align-self: center;
}

.menu a {
    color: #696969;
    font-size: 25px;
    font-family: Rubik;
}

.menu a:hover {
    border-bottom: 3px solid #696969;
    cursor: pointer;
}

.footer {
    text-align: center;
    padding: 2em;
    margin-top: 2em;
}

.portfolio {
    padding: 2em;
}

.portfolio h3 {
    color: #696969;
}

.portfolio h5 {
    font-size: 20px;
}

.proj-text p {
    color: #272525;
}

.phone-mockup {
    /* vertical-align: middle; */
    height: 25em;
    margin-bottom: 0;
}

.phone-showcase {
    /* vertical-align: middle; */
    height: 15em;
    margin-bottom: 0;
}

.phone-show {
    /* vertical-align: middle; */
    height: 13.5em;
    margin-bottom: 0;
}

.proj-text {
    margin: 2em 0;
}


@media only screen and (max-width: 310px) {
    .content-container p {
        font-family: Rubik;
        color: #fff;
        font-size: 14px;
        text-align: center;
    }

    .content-container h3 {
        font-family: Roboto Mono;
        font-size: 23px;
        margin-bottom: 2em;
        text-align: center;
    }
    .content-container h4 {
        font-family: Roboto Mono;
        font-size: 15px;
        color: #696969;
        margin-bottom: 2em;
        text-align: center;
    }
}

/*for screens less than 400 */
@media only screen and (max-width: 400px) {

    .content-container {
        background-color: #d3d3d3;
        padding: 0;
        text-align: center;
    }

    .content-container p {
        font-family: Rubik;
        color: #fff;
        font-size: 18px;
        text-align: center;
    }

    .content-container h3 {
        font-family: Roboto Mono;
        font-size: 26px;
        margin-bottom: 2em;
        text-align: center;
    }
    .content-container h4 {
        font-family: Roboto Mono;
        font-size: 18px;
        color: #696969;
        margin-bottom: 2em;
        text-align: center;
    }

    .content-container ul {
        padding: 0;
        margin: 0;
        display: block;
        padding-bottom: 1em;
    }

    .content-container li a {
        font-family: Rubik;
    }

    .content-container a:hover {
        border-bottom: 3px solid #55286F;
    }

    .sidebar-container {
        padding: .2em;
    }

    .phone-mockup {
        width: 15em;
        height: auto;
        align-self: center;
        margin-bottom: 0;
    }

    .phone-showcase {
        /* vertical-align: middle; */
        height: 10em;
        margin-bottom: 0;
    }
    
    .phone-show {
        /* vertical-align: middle; */
        height: 10em;
        margin-bottom: 0;
    }

    .proj-text p {
        color: #272525;
    }

    .proj-text h5 {
        color: #272525;
        font-family: Rubik;
    }
}


@media only screen and (max-width: 600px) {
    figure img {
        width: 3em;
        border-radius: 1.5em;
        margin: 0;
    }

    .content-container {
        padding-bottom: 7em;
    }
}

/* Mobile  screen less than 850 pixels*/
@media only screen and (max-width: 850px) {
    .hide-mobile {
        display: none;
    }

    .project-container {
        display: inline;
        justify-content: center;
    }

    .page-layout {
        display: flex;
        flex-direction: row;
        margin: 0;
    }

    .content-container {
        background-color: #d3d3d3;
        text-align: center;
        padding: 4.2em 1.3em 0 1.3em;
        margin: 3em 0 0 0;
    }

    .content-container p {
        text-align: center;
    }
    .content-container h3 {
        text-align: center;
    }
    .content-container h4 {
        text-align: center;
    }

    .sidebar-container {
        z-index: 2;
        height: 4.2em;
        width: 100%;
        background-color: rgba(255, 255, 255, 1);
    }

    .top-bar {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .top-bar h4 {
        color: #fff;
        text-decoration: none;
        padding: 1em 1em;
    }

    .top-bar h4 a:hover {
        border-bottom: 3px solid #55286F;
    }

    figure img {
        width: 3em;
        border-radius: 1.5em;
        margin: 0;
    }

    .proj-text {
        margin: 0;
        margin-bottom: 1em;
    }

    .phone-mockup {
        margin-bottom: 0;
    }

    .phone-showcase {
        /* vertical-align: middle; */
        height: 10em;
        margin-bottom: 0;
    }
    
    .phone-show {
        /* vertical-align: middle; */
        height: 8em;
        margin-bottom: 0;
    }
}

@media only screen and (min-width:851px) {
    .menu {
        display: none;
    }

    .hide-desktop {
        display: none;
    }

    .content-container {
        padding: 7em 13em 3em 2em;
        position: fixed;
        left: 10em;
        overflow-y: auto;
    }

    .content-container ul {
        justify-content: left;
    }
    
    .content-container li {
        padding-right: 3em;
    }

    .footer {
        display: none;
    }

    .portfolio {
        padding-top: 4em;
    }
}

/* For /Project larger than 900px  */
@media only screen and (min-width: 900px) {
    .project-container {
        justify-content: center;
    }


    .project-container {
        display: flex;
        align-items: center;
    }

    .proj-text {
        text-align: left;
    }

    .proj-text p {
        /* width: 90%; */
        /* align-self: center; */
    }
}

@media only screen and (max-width: 1040px) {
    .hide-extra {
        display: none;
    }
}

@media only screen and (min-width:1024px) {
    .cta {
        width: 50%;
        margin: 0 auto 4em;
    }

    .show-desktop {
        display: block;
        margin: 0 auto 13% auto;
    }

    .hide-desktop {
        display: none;
    }

    .content-container {
        padding: 10em 15em 10em 2em;
    }

    .scroll {
        width: 30px;
        animation: move 1s infinite alternate;
    }

    .portfolio {
        padding-top: 4em;
    }

    @keyframes move {
        0% {
            transform:  translateY(0px);
        }
        100% {
            transform:  translateY(20px);
        }
    }
}

@media only screen and (min-width:1600px) {
    .container {
        width: 70%;
    }
}